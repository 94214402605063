@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

ul {
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
  color: inherit;
}


/* Importing the Google Font */

/* Applying the font to the entire app */
body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  color: #333; /* Adjust text color if needed */
}

/* Additional global styles */
h1, h2, h3, h4, h5, h6 {
  font-weight: 500; /* Medium weight for headings */
  margin: 0;
}

p, span, li, a {
  font-weight: 400; /* Normal weight for text */
  line-height: 1.6;
}

button, input, textarea {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}
